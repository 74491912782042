.services-card {
    /*margin: 40px;*/
    box-shadow: 7px 13px 49px -5px rgba(86, 63, 130, 0.78);
    border-radius: 30px;
    padding: 10px 0;
}

.services-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px;
}

.services-table {
    display: grid;
    grid-template-areas:
    "services-table__head services-table__head services-table__head services-table__head services-table__head"
    "services-list services-price services-price services-price services-price"
    "services-list services-adult services-adult services-child services-child"
    "services-list services-weekday__adult services-weekends__adult services-weekday__child services-weekends__child"
    "free-swimming free-swimming free-swimming free-swimming free-swimming"
    "free-swimming__one-time__visit free-swimming__one-time__visit-weekday__adult free-swimming__one-time__visit-weekends__adult free-swimming__one-time__visit-weekday__child free-swimming__one-time__visit-weekends__child"
    "free-swimming__four-visits__per-month free-swimming__four-visits__per-month-weekday__adult free-swimming__four-visits__per-month-weekends__adult free-swimming__four-visits__per-month-weekday__child free-swimming__four-visits__per-month-weekends__child"
    "free-swimming__eight-visits__per-month free-swimming__eight-visits__per-month-weekday__adult free-swimming__eight-visits__per-month-weekends__adult free-swimming__eight-visits__per-month-weekday__child free-swimming__eight-visits__per-month-weekends__child"
    "free-swimming__twelve-visits__per-month free-swimming__twelve-visits__per-month-weekday__adult free-swimming__twelve-visits__per-month-weekends__adult free-swimming__twelve-visits__per-month-weekday__child free-swimming__twelve-visits__per-month-weekends__child"
    "preferential-visits preferential-visits preferential-visits preferential-visits preferential-visits"
    ". preferential-visits__students preferential-visits__students preferential-visits__staff preferential-visits__staff"
    "preferential-visits__one-time__visit preferential-visits__one-time__visit__students preferential-visits__one-time__visit__students preferential-visits__one-time__visit__staff preferential-visits__one-time__visit__staff"
    "preferential-visits__four-visits__per-month preferential-visits__four-visits__per-month-weekday__students preferential-visits__four-visits__per-month-weekday__students preferential-visits__four-visits__per-month-weekends__staff preferential-visits__four-visits__per-month-weekends__staff"
    "preferential-visits__eight-visits__per-month preferential-visits__eight-visits__per-month-weekday__students preferential-visits__eight-visits__per-month-weekday__students preferential-visits__eight-visits__per-month-weekends__staff preferential-visits__eight-visits__per-month-weekends__staff"
    "preferential-visits__twelve-visits__per-month preferential-visits__twelve-visits__per-month-weekday__students preferential-visits__twelve-visits__per-month-weekday__students preferential-visits__twelve-visits__per-month-weekends__staff preferential-visits__twelve-visits__per-month-weekends__staff"
    "pensioners pensioners pensioners pensioners pensioners"
    "pensioners__one-time__visit pensioners__one-time__visit__students pensioners__one-time__visit__students pensioners__one-time__visit__staff pensioners__one-time__visit__staff"
    "pensioners__four-visits__per-month pensioners__four-visits__per-month-weekday__students pensioners__four-visits__per-month-weekday__students pensioners__four-visits__per-month-weekends__staff pensioners__four-visits__per-month-weekends__staff"
    "pensioners__eight-visits__per-month pensioners__eight-visits__per-month-weekday__students pensioners__eight-visits__per-month-weekday__students pensioners__eight-visits__per-month-weekends__staff pensioners__eight-visits__per-month-weekends__staff"
    "pensioners__twelve-visits__per-month pensioners__twelve-visits__per-month-weekday__students pensioners__twelve-visits__per-month-weekday__students pensioners-visits__twelve-visits__per-month-weekends__staff pensioners-visits__twelve-visits__per-month-weekends__staff"
    "other-services other-services other-services other-services other-services"
    "other-services__six-tracks other-services__six-tracks__students other-services__six-tracks__students other-services__six-tracks__staff other-services__six-tracks__staff"
    "other-services__one-track other-services__one-track__students other-services__one-track__students other-services__one-track__staff other-services__one-track__staff"
    "services-table__text services-table__text services-table__text services-table__text services-table__text";
    /*grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;*/
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin: 0;
}

.services-table > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid grey;
    /*font-weight: bold;*/
    margin: 0;
    text-align: center;
    padding: 5px 10px;
}

.services-table__head {
    display: block;
    font-weight: bold;
    width: 100%;
    grid-area: services-table__head;
    font-size: 20px;
    text-align: center;
}

.services-table__text {
    display: block;
    width: 100%;
    grid-area: services-table__text;
    font-size: 15px;
    text-align: center;
}

.services-list {
    grid-area: services-list;
    font-weight: bold;
}

.services-price {
    grid-area: services-price;
    font-weight: bold;
}

.services-adult {
    grid-area: services-adult;
    font-weight: bold;
}

.services-child {
    grid-area: services-child;
    font-weight: bold;
}

.services-weekday__adult {
    grid-area: services-weekday__adult;
    font-weight: bold;
}

.services-weekends__adult {
    grid-area: services-weekends__adult;
    font-weight: bold;
}

.services-weekday__child {
    grid-area: services-weekday__child;
    font-weight: bold;
}

.services-weekends__child {
    grid-area: services-weekends__child;
    font-weight: bold;
}

.free-swimming {
    grid-area: free-swimming;
    font-weight: bold;
}

.free-swimming__one-time__visit {
    grid-area: free-swimming__one-time__visit;
}

.free-swimming__one-time__visit-weekday__adult {
    grid-area: free-swimming__one-time__visit-weekday__adult;
}

.free-swimming__one-time__visit-weekends__adult {
    grid-area: free-swimming__one-time__visit-weekends__adult;
}

.free-swimming__one-time__visit-weekday__child {
    grid-area: free-swimming__one-time__visit-weekday__child;
}

.free-swimming__one-time__visit-weekends__child {
    grid-area: free-swimming__one-time__visit-weekends__child;
}

.free-swimming__four-visits__per-month {
    grid-area: free-swimming__four-visits__per-month;
}

.free-swimming__four-visits__per-month-weekday__adult {
    grid-area: free-swimming__four-visits__per-month-weekday__adult;
}

.free-swimming__four-visits__per-month-weekends__adult {
    grid-area: free-swimming__four-visits__per-month-weekends__adult;
}

.free-swimming__four-visits__per-month-weekday__child {
    grid-area: free-swimming__four-visits__per-month-weekday__child;
}

.free-swimming__four-visits__per-month-weekends__child {
    grid-area: free-swimming__four-visits__per-month-weekends__child;
}

.free-swimming__eight-visits__per-month {
    grid-area: free-swimming__eight-visits__per-month;
}

.free-swimming__eight-visits__per-month-weekday__adult {
    grid-area: free-swimming__eight-visits__per-month-weekday__adult;
}

.free-swimming__eight-visits__per-month-weekends__adult {
    grid-area: free-swimming__eight-visits__per-month-weekends__adult;
}

.free-swimming__eight-visits__per-month-weekday__child {
    grid-area: free-swimming__eight-visits__per-month-weekday__child;
}

.free-swimming__eight-visits__per-month-weekends__child {
    grid-area: free-swimming__eight-visits__per-month-weekends__child;
}

.free-swimming__twelve-visits__per-month {
    grid-area: free-swimming__twelve-visits__per-month;
}

.free-swimming__twelve-visits__per-month-weekday__adult {
    grid-area: free-swimming__twelve-visits__per-month-weekday__adult;
}

.free-swimming__twelve-visits__per-month-weekends__adult {
    grid-area: free-swimming__twelve-visits__per-month-weekends__adult;
}

.free-swimming__twelve-visits__per-month-weekday__child {
    grid-area: free-swimming__twelve-visits__per-month-weekday__child;
}

.free-swimming__twelve-visits__per-month-weekends__child {
    grid-area: free-swimming__twelve-visits__per-month-weekends__child;
}

.preferential-visits {
    grid-area: preferential-visits;
    font-weight: bold;
}

.preferential-visits__students {
    grid-area: preferential-visits__students;
}

.preferential-visits__staff {
    grid-area: preferential-visits__staff;
}

.preferential-visits__one-time__visit {
    grid-area: preferential-visits__one-time__visit;
}

.preferential-visits__one-time__visit__students {
    grid-area: preferential-visits__one-time__visit__students;
}

.preferential-visits__one-time__visit__staff {
    grid-area: preferential-visits__one-time__visit__staff;
}

.preferential-visits__four-visits__per-month {
    grid-area: preferential-visits__four-visits__per-month;
}

.preferential-visits__four-visits__per-month-weekday__students {
    grid-area: preferential-visits__four-visits__per-month-weekday__students;
}

.preferential-visits__four-visits__per-month-weekends__staff {
    grid-area: preferential-visits__four-visits__per-month-weekends__staff;
}

.preferential-visits__eight-visits__per-month {
    grid-area: preferential-visits__eight-visits__per-month;
}

.preferential-visits__eight-visits__per-month-weekday__students {
    grid-area: preferential-visits__eight-visits__per-month-weekday__students;
}

.preferential-visits__eight-visits__per-month-weekends__staff {
    grid-area: preferential-visits__eight-visits__per-month-weekends__staff;
}

.preferential-visits__twelve-visits__per-month {
    grid-area: preferential-visits__twelve-visits__per-month;
}

.preferential-visits__twelve-visits__per-month-weekday__students {
    grid-area: preferential-visits__twelve-visits__per-month-weekday__students;
}

.preferential-visits__twelve-visits__per-month-weekends__staff {
    grid-area: preferential-visits__twelve-visits__per-month-weekends__staff;
}

.pensioners {
    grid-area: pensioners;
    font-weight: bold;
}

.pensioners__one-time__visit {
    grid-area: pensioners__one-time__visit;
}

.pensioners__one-time__visit__students {
    grid-area: pensioners__one-time__visit__students;
}

.pensioners__one-time__visit__staff {
    grid-area: pensioners__one-time__visit__staff;
}

.pensioners__four-visits__per-month {
    grid-area: pensioners__four-visits__per-month;
}

.pensioners__four-visits__per-month-weekday__students {
    grid-area: pensioners__four-visits__per-month-weekday__students;
}

.pensioners__four-visits__per-month-weekends__staff {
    grid-area: pensioners__four-visits__per-month-weekends__staff;
}

.pensioners__eight-visits__per-month {
    grid-area: pensioners__eight-visits__per-month;
}

.pensioners__eight-visits__per-month-weekday__students {
    grid-area: pensioners__eight-visits__per-month-weekday__students;
}

.pensioners__eight-visits__per-month-weekends__staff {
    grid-area: pensioners__eight-visits__per-month-weekends__staff;
}

.pensioners__twelve-visits__per-month {
    grid-area: pensioners__twelve-visits__per-month;
}

.pensioners__twelve-visits__per-month-weekday__students {
    grid-area: pensioners__twelve-visits__per-month-weekday__students;
}

.pensioners-visits__twelve-visits__per-month-weekends__staff {
    grid-area: pensioners-visits__twelve-visits__per-month-weekends__staff;
}

.other-services {
    grid-area: other-services;
    font-weight: bold;
}

.other-services__six-tracks {
    grid-area: other-services__six-tracks;
}

.other-services__six-tracks__students {
    grid-area: other-services__six-tracks__students;
}

.other-services__six-tracks__staff {
    grid-area: other-services__six-tracks__staff;
}

.other-services__one-track {
    grid-area: other-services__one-track;
}

.other-services__one-track__students {
    grid-area: other-services__one-track__students;
}

.other-services__one-track__staff {
    grid-area: other-services__one-track__staff;
}

.services-left {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.services-left-img {
    width: 500px;
    height: 300px;
    transform: scale(-1, 1);
}

.services-list {
    list-style: none;
    padding: 0;
}

.services-list-items {
    display: flex;
    justify-content: space-between;
}

.rub {
    vertical-align: middle;
    border-bottom: 1px solid;
    line-height: 5px;
    display: inline-block;
    width: 0.4em;
}

.price__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.price__descr {
    width: 300px;
    text-align: left;
}

.price-collapse .price__item {
    margin-bottom: 5px;
}

.collapse {
    margin-bottom: 20px;
    margin-left: 32px;
    cursor: pointer;
}

.price__add {
    font-weight: 600;
    color: #292929;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
    width: 450px;
}

.price {
    font-size: 17px;
    font-weight: 700;
    width: 70px;
}

.day-price {
    color: #3d2f71;
}

.icon {
    transition: all .4s linear;
    font-size: 20px;
    width: 22px;
}

.icon-add {
    color: #96d2ff;
}

.icon-add:hover {
    color: #319cec;
}

.icon-delete {
    color: #92da8a;
}

.icon-delete:hover {
    color: #f37490;
}

.icon-delete:hover::before {
    content: "\f057";
}

.collapse__button {
    display: flex;
}

.collapse__switch {
    width: 60px;
}

.switch__icon {
    color: #808080;
}

.price__summe {
    display: flex;
    align-items: center;
}

.summe-span {
    transition: all .4s linear;
}

.total-heading {
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.price__summe {
    display: flex;
    justify-content: flex-end;
}

.summe-span {
    font-weight: 700;
    font-size: 32px;
}

@media screen and (max-width: 1100px) {
    .services-wrap {
        display: block;;
    }
}

@media (max-width: 768px) {
    .services-table {
        overflow-y: auto;
    }
}

